import * as React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Socials from '../components/socials';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { projects, skills } from '../../resume';

const IndexPage = () => (
  <Layout>
    <Seo title="Connie Lok - Software Engineer" />
    <div className="page-banner">
      <div className="content-title">
        <div className="name">
          <h1>CONNIE LOK<span>Software Engineer</span></h1>
        </div>
        <Socials />
      </div>
    </div>

    <section className="bio">
      <div id="about" className="content-section">
        <h2>About</h2>
        <div>
          <p>Full Stack Software Engineer</p>
          <p>Based in New York, NY</p>
          <p>Grace Hopper Program at Fullstack Academy alum</p>
          <p>Electrical Engineering, B.Eng, City College of New York</p>
        </div>
      </div>
      <div className="work content-section">
        <h2>Work</h2>
        <div>
          <p>Software Engineer, GeistM <span>Nov 2020 - Present</span></p>
          <p>Electrical Engineer, AKF <span>Feb 2016 - Feb 2020</span></p>
        </div>
      </div>
      <div id="projects" className="content-section">
        <h2>Projects</h2>
        <div>
          {projects.map(proj => (
            <div className="project-info">
              <div className="project-title-wrapper">
                <a className="project-title" href={proj.href} aria-label={`${proj.name} ${proj.medium ? 'Article' : 'Github Repo'}`}>{proj.name}</a>
                <div>
                  {proj.medium ?
                    <a href={proj.href} aria-label={`${proj.name} Article`}><FontAwesomeIcon icon={['fab', 'medium']} /></a> :
                    <a href={proj.href} aria-label={`${proj.name} Github Repo`}><FontAwesomeIcon icon={['fab', 'github-square']} /></a>}
                </div>
              </div>
              <ul>
                <li>{proj.description1}</li>
                {proj.description2 && <li>{proj.description2}</li>}
                <li>Built with: {proj.tech}</li>
              </ul>
            </div>
          ))}
        </div>
      </div>
      <div className="skills content-section">
        <h2>Skills</h2>
        <div>
          <ul>
            {skills.map(skill => <li>{skill}</li>)}
          </ul>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
