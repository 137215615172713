interface Project {
  name: string,
  description1: string,
  description2?: string,
  tech?: string,
  href: string,
  medium?: boolean,
}

export const projects: Project[] = [
  {
    name: 'NYC Food Chatbot',
    description1: 'A chatbot that makes restaurant suggestions based on the type of cuisine a user suggests.',
    description2: 'Developed during a Mintbean "Hack the Chatbot" hackathon.',
    tech: 'Botkit, Zomato API',
    href: 'https://github.com/connielok/food-chatbot',
  },
  {
    name: 'Keep It Secret',
    description1:
      'A serverless application that allows users to share secret messages with those they trust. Check out the related medium article for more information on the serverless architecture below.',
    description2: 'Developed during the Grace Hopper program.',
    tech: 'AWS Lambda, Amazon DynamoDB, Amazon API Gateway, React',
    href: 'https://www.rb.gy/fjdu9q',
  },
  {
    name: 'Lift Log',
    description1:
      'A weightlifting exercise tracker that automatically detects and logs exercises as you are working out, eliminating the need of manually tracking exercises.',
    description2: 'Developed with a team during the Grace Hopper program.',
    tech: 'React, Express, PostgreSQL, Sequelize, Teachable Machine model, Material UI',
    href: 'https://github.com/capstone-2004-team-awesome/Lift-Log',
  },
  {
    name: 'Pandemic Essentials',
    description1:
      'An e-commerce site that offers products deemed essential during a national pandemic.',
    description2: 'Developed with a team during the Grace Hopper program.',
    tech: 'React, Redux, Express, PostgreSQL, Sequelize, Mocha, Chai',
    href: 'https://www.rb.gy/keatkh',
  },
  {
    name: 'Building Serverless Back-end with AWS',
    description1: 'A tutorial detailing how to build a serverless back-end using AWS Lambda, Amazon DynamoDB, and Amazon API Gateway.',
    href: 'https://medium.com/@connielok/building-a-serverless-back-end-with-aws-5bb3642a3f4',
    medium: true,
  },
];

export const skills = ['JavaScript', 'Node.js', 'Express', 'React', 'Redux', 'Next.js', 'HTML', 'CSS', 'RESTful API', 'Sequelize', 'SQL', 'PostgreSQL', 'AWS', 'MongoDB', 'TypeScript'];
